import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle'
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips'
import { MatNativeDateModule } from '@angular/material/core'
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs'

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDialogModule,
		MatMenuModule,
		MatTableModule,
		MatSlideToggleModule,
		MatNativeDateModule,
		MatCardModule,
		MatChipsModule,
		MatRadioModule,
		NgxMaterialTimepickerModule.setOpts(
			localStorage.getItem('language') == 'ar' ? 'ar-AE' : 'en-US',
			'arab'
		),
		MatSidenavModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatSliderModule,
		MatOptionModule,
		CdkAccordionModule,
		MatTabsModule,
	],
	exports: [
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDialogModule,
		MatMenuModule,
		MatTableModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatRadioModule,
		NgxMaterialTimepickerModule,
		MatSidenavModule,
		MatButtonToggleModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatSliderModule,
		MatOptionModule,
		CdkAccordionModule,
		MatTabsModule,
		MatCardModule,
	],
})
export class MaterialsModule {}
